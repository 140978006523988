body {
  font-family: 'Varela', sans-serif;
  padding-bottom: 3rem;
  color: #fff;
  background-color: #555;
  background: url(tiki-cat-background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
h1 {
  font-family: 'Acme', sans-serif;
  font-size: 4rem;
}

a,
a:hover {
  color: #fc6;
}

.jumbotron,
nav {
  border-bottom: #fc6 2px dotted;
}

.washout {
  background-color: #555c;
}

h3.washout {
  padding: 0.5em;
  border-bottom: #fc6 2px dotted;
}

.section {
  margin-bottom: 3em;
}
